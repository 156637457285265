import styled from "styled-components"
import { theme } from "UI/theme"

export const ButtonBox = styled.div`
  position: fixed;
  z-index: 100;
  top: 4%;
  left: 2%;
  display: flex;
  align-items: center;
  
  &:hover {
    > button {
      background: ${theme.palette.neutral.light} content-box;
    }
  }

  @media (max-width: 768px) {
    top: 100px;
    left: 0;
    justify-content: center;
    width: 100%;
    height: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #df4a32;
  }
`

export const BackButton = styled.button`
  cursor: pointer;
  border-radius: 50%;
  min-width: ${theme.space[4]}px;
  min-height: ${theme.space[4]}px;
  margin: 0 ${theme.space[1]}px;
  padding: ${theme.space[1]}px;
  transition: 0.3s all;
  border: 1px dashed ${theme.palette.neutral.light};
  background: ${theme.palette.accent.red} content-box;
`
