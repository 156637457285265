import React from "react"
import Typography from "components/Typography"
import { ButtonBox, BackButton } from "./styles"
import { isMobile } from "utils"

const Back = ({ title, en }) => {
  return (
    <ButtonBox>
      {!isMobile && (
        <>
          <BackButton>
            <img src="/arrow_back.svg" width="54%" height="auto" />
          </BackButton>
          <Typography
            variant="langButton"
            color="text.primary"
            marginLeft={"2px"}
          >
            {title}
          </Typography>
        </>
      )}
    </ButtonBox>
  )
}

export default Back
