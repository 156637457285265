import styled from "styled-components"

export const Box = styled.div``

export const Section = styled.section`
  display: flex;
  position: fixed;
  width: 100%;
  height: 100vh;
  @media (max-width: 992px) {
    background-color: #23191d;
  }
`

export const Wrapper = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 44.75% 56.25%;

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }
`

export const SectionElement = styled.div`
  padding-left: 120px;
  padding-right: 5vw;
  padding-top: 15px;
  padding-bottom: 15px;

  @media (max-width: 992px) {
    padding-left: 65px;
    padding-right: 15px;
  }
  @media (max-width: 768px) {
    padding-left: 22px;
    padding-right: 19px;
    /* padding-top: 70px; */
  }
`

export const ALayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto 1fr;
  grid-gap: 30px;

  > h3 {
    grid-column: 2 / span 1;
  }

  > div {
    grid-column: 2 / span 1;
  }

  > figure {
    grid-column: 1 / span 1;
    grid-row: 1 / span 2;
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    h3 {
      margin-top: 50px;
    }
  }
`

export const BLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto 1fr;
  grid-gap: 30px;

  > h3 {
    grid-column: 1 / span 2;
  }

  > div {
    grid-column: 1 / span 1;
  }

  > figure {
    grid-column: 2 / span 1;
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    h3 {
      margin-top: 50px;
    }
  }
`

export const CLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  > div {
    margin-top: 25px;
  }
`

export const Intro = styled.div`
  margin-bottom: 116px;

  @media (max-width: 768px) {
    margin-bottom: 40px;
  }
`

export const SectionImageWithBorder = styled.img`
  margin-top: 20px;
  margin-left: 20px;
  margin-bottom: -25px;
  cursor: pointer;
  @media (max-width: 768px) {
    margin: 0;
  }
`
export const SectionFigureWithBorder = styled.figure`
  border: 1px dashed rgba(218, 210, 210, 0.1);
  margin-top: -20px;
  margin-left: -20px;
  margin-bottom: 6rem;
  position: relative;
  height: fit-content;
  @media (max-width: 768px) {
    border: none;
    margin: 0;
    position: static;
  }
`
export const SectionImage = styled.img`
  cursor: pointer;
`
export const SectionFigure = styled.figure`
  height: fit-content;
`
export const SectionFigcaption = styled.figcaption`
  opacity: 0.7;
  color: #dad2d2;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.065em;
  line-height: 1.43em;
  text-align: left;
`
export const SectionFigcaptionWithBorder = styled(SectionFigcaption)`
  position: absolute;
  bottom: -9%;
  transform: translate(20px, 100%);
  @media (max-width: 768px) {
    position: static;
    transform: translate(0, 0);
  }
`
export const FadeBottom = styled.div`
  width: 100%;
  height: 150px;
  position: fixed;
  left: 0;
  bottom: 0;
  background: linear-gradient(rgba(0, 0, 0, 0), #161213);
  z-index: 999;
`
export const SeeMoreContainer = styled.div`
  position: relative;
`
export const SeeMoreButton = styled.button`
  width: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.palette.accent.red};
  outline: none;
  border: none;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
`

export const NextButton = styled.button`
  width: 230px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.palette.accent.red};
  outline: none;
  border: none;
  cursor: pointer;
  padding: 5px 0;
  align-self: center;
  margin-top: 14px;
`

export const AboutSection = styled.section`
  display: flex;
  padding: 120px 30px 30px 30px;

  @media (max-width: 992px) {
    padding: 160px 15px 15px 15px;
    flex-direction: column;
  }
`

export const AboutParagraph = styled.p`
  color: #f2eeed;
  font-family: "Oswald", sans-serif;
  font-size: 22px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0.08;
  line-height: 1.6;
  text-align: left;
  margin-bottom: 1em;

  @media (max-width: 992px) {
    font-size: 14px;
  }
`

export const AboutHeadline = styled.h3`
  color: #df4a32;
  font-family: "Oswald", sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0.08em;
  margin-bottom: 0.5em;
  text-align: left;
  text-transform: uppercase;

  @media (max-width: 992px) {
    font-size: 12px;
  }
`

export const AboutBox = styled.div`
  padding: 30px;
  flex: 1 1 50%;

  @media (max-width: 992px) {
    flex: 1 1 100%;
    padding: 15px;
  }
`

export const AboutWrapper = styled.div`
  height: 100vh;
  max-height: 100vh;
  overflow-y: scroll;
  position: fixed;
  z-index: 10;
`

export const PlayerWrapper = styled.div`
  margin-bottom: 30px;
`

export const VideoWrapper = styled.div`
  position: relative;
  padding-bottom: 56.25%;

  video {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`

export const PlayerCaption = styled.p`
  opacity: 0.7;
  color: #dad2d2;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.065em;
  line-height: 1.43em;
  text-align: left;
  padding: 0.5em 0;
`
